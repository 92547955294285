.banner {
  text-align: center;
  padding: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 21px;
  .dd-logo {
    height: 18px;
    width: 154px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0px 5px;
  }
  .ordering-logo {
    height: 20px;
    width: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 5px;
  }
}
