.home {
  .video-borders {
    position: relative;
    height: 70vh;
    background: #111111;
    @media only screen and (max-width: 960px) {
      height: max-content;
      padding-bottom: 56%;
    }

    iframe {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }
  .hero {
    height: calc(100vh - 60px);
    &.small {
      height: 300px;
    }
    h1 {
      color: white;
      margin-left: 50px;
      @media only screen and (max-width: 960px) {
        margin-left: 20px;
      }
    }
  }
  .content-row {
    padding: 50px 10px;
    text-align: center;
    h2 {
      margin-top: 0px;
    }
  }
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin: 50px;
    @media only screen and (max-width: 960px) {
      margin: 50px 0px;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 30px;
    }
    .item {
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 100%;
    }
  }

  .chef-grid {
    display: grid;
    grid-row-gap: 50px;
    margin-bottom: 50px;
    .chef {
      flex-direction: column;
      display: flex;
      align-items: center;
      .portrait {
        width: 300px;
        height: 300px;
      }
    }
  }
}
