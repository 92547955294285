.footer {
  background: #111111;
  color: white;
  padding: 80px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    padding: 50px 10px;
    flex-direction: column;
  }
  a {
    color: white;
    &:visited {
      color: white;
    }
  }
  .info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    .hours {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      @media only screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .social {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    @media only screen and (max-width: 960px) {
      display: initial;
      margin-top: 20px;
    }
    img {
      height: 50px;
      @media only screen and (max-width: 960px) {
        margin-right: 10px;
      }
    }
  }
}
