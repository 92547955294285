.reservations {
  .hero {
    min-height: calc(100vh - 60px);
    position: relative;
    h1 {
      color: white;
    }
    .content {
      // position: absolute;
      width: calc(100vw - 100px);
      margin: 0px 50px 50px 50px;
      @media only screen and (max-width: 960px) {
        width: calc(100vw - 40px);
        margin: 0px 20px 50px 20px;
      }
      .content-row {
        background: white;
        padding: 60px;
        @media only screen and (max-width: 960px) {
          padding: 20px 10px;
        }
      }
    }
  }

}