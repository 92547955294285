.app {
  font-family: Lato;
  .general-container {
    margin-top: 60px;
    min-height: calc(100vh - 60px);
    background-color: #eeeeee;
  }

  .center {
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 960px) {
    .mobile-hidden {
      display: none !important;
    }
  }
}
