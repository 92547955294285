.ordering-button {
  font-size: 22px;

  div & .btn-img {
    width: 300px;
    height: 80px;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 960px) {
      width: 190px;
      height: 50px;
    }
  }
}
