.location {
  .hero {
    min-height: calc(100vh - 60px);
    position: relative;
    h1 {
      color: white;
    }
    .content {
      width: calc(30vw - 40px);
      margin: 0px 20px 50px 20px;

      @media only screen and (max-width: 960px) {
        width: calc(100vw - 40px);
        left: initial;
      }
      .content-row {
        background: white;
        padding: 50px 60px;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        font-size: 16px;

        .address {
          margin-bottom: 20px;
        }
      }
    }
  }

}