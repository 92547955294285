.menu {
  .order-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    flex-wrap: wrap;
    a {
      margin: 20px;
    }
  }
  .hero {
    min-height: calc(100vh - 60px);
    position: relative;
    h1 {
      color: white;
    }
    h2 {
      text-align: center;
    }
    .content {
      // position: absolute;
      width: calc(100vw - 100px);
      margin: 0px 50px 50px 50px;
      @media only screen and (max-width: 960px) {
        width: calc(100vw - 40px);
        margin: 0px 20px 50px 20px;
      }
      .content-row {
        background: white;
        padding: 60px;
        min-height: 300px;
        @media only screen and (max-width: 960px) {
          padding: 10px;
        }
        .menu-container {
          display: flex;
          justify-content: center;
          @media only screen and (max-width: 960px) {
            display: initial;
          }
          .menu {
            @media only screen and (max-width: 960px) {
              width: 100%;
            }
            @media only screen and (min-width: 961px) {
              max-height: 1000px;
              max-width: 65vw;
            }
          }
        }
      }
    }
  }
}
