.navbar {
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  a.logo-link, img {
    height: 100%;
  }
  .links {
    display: flex;
    @media only screen and (max-width: 960px) {
      flex: 1;
      padding-right: 5px;
    }
    a {
      padding: 0px 10px;
      color: black;
      font-size: 13px;
      @media only screen and (max-width: 960px) {
        padding: 0px 5px;
        flex: 1;
        display: flex;
        justify-content: center;
      }
      &:hover {
        color: gray;
      }
    }
  }
}